import { computed, Pipe, PipeTransform, Signal } from '@angular/core';
import { LanguageService } from './language.service';
import {I18NPath} from "./i18n-types";
import i18next from "i18next";

@Pipe({
  name: 'i18n',
})
export class I18nPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}

  transform(value: I18NPath, options?: any): Signal<string> {
    return computed(() => {
      this.languageService.currentLang$$();
      return i18next.t('app:' + value, options) as any;
    });
  }

  /**
   * As much as possible, try to use this in a "computed" signal, and read the languageService.currentLanguage$$ to have it flushed on language change
   * @param value
   * @param options
   */
  v(value: I18NPath, options?: any): string {
    return this.transform(value, options)();
  }
}
