import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {IonicModule} from "@ionic/angular";
import {ErrorComponent} from "./error/error.component";
import {HamoI18nModule} from "../hamo-i18n.module";
import {NoMoreItemsComponent} from "./no-more-items/no-more-items.component";
import {NoItemsFoundComponent} from "./no-items-found/no-items-found.component";
import {IfLoadingDirective} from "./if-loading.directive";
import {GenericListComponent} from "./generic-list/generic-list.component";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterLink} from "@angular/router";
import {ItemStatusRibbonComponent} from "./item-status-ribbon/item-status-ribbon.component";
import {RequestDatePipe} from "./request-date.pipe";
import {LongPressDirective} from "./long-press.directive";
import {ConfirmDeleteComponent} from "../../item/confirm-delete/confirm-delete.component";
import {GoogleLoginButtonComponent} from "./google-login-button/google-login-button.component";
import {TriggerScrollOnStableDirective} from "./trigger-scroll-on-stable.directive";


@NgModule({
  declarations: [
    ErrorComponent,
    NoMoreItemsComponent,
    NoItemsFoundComponent,
    IfLoadingDirective,
    GenericListComponent,
    ItemStatusRibbonComponent,
    RequestDatePipe,
    LongPressDirective,
    GoogleLoginButtonComponent,
    TriggerScrollOnStableDirective
  ],
  imports: [
    CommonModule,
    IonicModule,
    HamoI18nModule,
    ScrollingModule,
    ReactiveFormsModule,
    RouterLink
  ],
  exports: [
    ErrorComponent,
    NoMoreItemsComponent,
    NoItemsFoundComponent,
    IfLoadingDirective,
    GenericListComponent,
    ItemStatusRibbonComponent,
    RequestDatePipe,
    LongPressDirective,
    GoogleLoginButtonComponent,
    TriggerScrollOnStableDirective
  ],
  providers: [
    DatePipe
  ]
})
export class HamoCommonModule {
}
