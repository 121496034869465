<ion-app>
  <ion-progress-bar style="position: absolute;top:0;left:0;right:0;z-index:10000" *appIfLoading
                    type="indeterminate"></ion-progress-bar>
  @if (loginStatus() === LoginStatus.LoggedIn) {
    <ion-menu contentId="main-content" data-test="popup-menu">
      <ion-header>
        <ion-toolbar>
          <ion-title>{{ 'menu.appTitle' | i18n | signal }}</ion-title>
          <ion-buttons slot="end">
            <ion-menu-toggle>
              <ion-button data-test="menu-toggle">
                <ion-icon name="close-outline"></ion-icon>
              </ion-button>
            </ion-menu-toggle>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <app-hamo-menu></app-hamo-menu>
      </ion-content>
    </ion-menu>

    <ion-grid style="height: 100%;width:100%" class="ion-no-padding">
      <ion-row style="height: 100%;width:100%" class="ion-no-padding">
        <ion-col class="ion-hide-lg-down" style="flex-grow:1">
          <ion-content>
            <ion-header class="ion-no-border">
              <ion-toolbar >
                <ion-title>{{ 'menu.appTitle' | i18n | signal }}</ion-title>
              </ion-toolbar>
            </ion-header>
            <app-hamo-menu></app-hamo-menu>
          </ion-content>
        </ion-col>
        <ion-col style="flex-grow: 4">
          <ion-router-outlet id="main-content"></ion-router-outlet>
        </ion-col>
      </ion-row>
    </ion-grid>


  }
  @if (loginStatus() === LoginStatus.Unauthorized) {
    <ion-grid>
      <ion-row class="ion-align-items-center">
        <ion-col>
          <ion-card>
            <ion-card-content data-test="no-yet-message">
              {{ 'home.noAccessYet' | i18n | signal }}
            </ion-card-content>
          </ion-card>
          <ion-card>
            @for (language of LanguageService.langList; track language) {
              <ion-button [disabled]="language === languageService.currentLang$$()" fill="clear"
                          (click)="languageService.changeLang(language)">
                {{ LanguageService.langNames[language] }}
                @if (language === languageService.currentLang$$()) {
                  ({{ 'menu.languageSelected' | i18n | signal }})
                }
              </ion-button>
            }
          </ion-card>
          <form action="/logout" method="POST" #logoutForm></form>
          <ion-card>
            <ion-button fill="clear" (click)="logoutForm.submit()" data-test="logout-button">
              <ion-icon name="exit-outline" slot="start"></ion-icon>
              <ion-label>{{ 'menu.logout' | i18n | signal }}</ion-label>
            </ion-button>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  }
  @if (loginStatus() === LoginStatus.LoggedOut) {
    <app-login-page></app-login-page>

  }
</ion-app>
