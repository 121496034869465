/**
 * Hamo contract
 * Hamo contract
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RequestStatus = 'CREATED' | 'REQUESTED' | 'ACCEPTED' | 'REQUEST_DECLINED' | 'CANCELLED' | 'ITEM_BORROWED' | 'ITEM_GIFTED' | 'ITEM_GIVEN_BACK' | 'ITEM_CANNOT_BE_GIVEN_BACK';

export const RequestStatus = {
    Created: 'CREATED' as RequestStatus,
    Requested: 'REQUESTED' as RequestStatus,
    Accepted: 'ACCEPTED' as RequestStatus,
    RequestDeclined: 'REQUEST_DECLINED' as RequestStatus,
    Cancelled: 'CANCELLED' as RequestStatus,
    ItemBorrowed: 'ITEM_BORROWED' as RequestStatus,
    ItemGifted: 'ITEM_GIFTED' as RequestStatus,
    ItemGivenBack: 'ITEM_GIVEN_BACK' as RequestStatus,
    ItemCannotBeGivenBack: 'ITEM_CANNOT_BE_GIVEN_BACK' as RequestStatus
};

