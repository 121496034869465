/**
 * Hamo contract
 * Hamo contract
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type LanguageEnum = 'en' | 'fr' | 'nl';

export const LanguageEnum = {
    En: 'en' as LanguageEnum,
    Fr: 'fr' as LanguageEnum,
    Nl: 'nl' as LanguageEnum
};

