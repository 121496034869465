import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-google-login-button',
  templateUrl: './google-login-button.component.html',
  styleUrls: ['./google-login-button.component.scss'],
})
export class GoogleLoginButtonComponent  {

  constructor() { }

  login() {
    window.location.href = "/oauth2/authorization/google";
  }
}
