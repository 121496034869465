import {I18NAppResource} from "./i18n-types";

export const i18nEn: I18NAppResource = {
  error: {
    message: 'Something went wrong...'
  },
  messages: {
    noItemFound: 'No items found...'
  },
  home: {
    welcomeTitle: "Welcome to Hamo",
    welcomeSubtitle: "Where Sharing Toys Builds Lasting Friendships",
    welcomeInvitationSubtitle: "{{name}} ({{email}}) has invited you to their community",
    pleaseLogin: `By signing up, you agree with our <a href="https://about.hamo.app/en/termsofuse" target="_blank">Terms of use</a> and <a href="https://about.hamo.app/en/privacypolicy" target="_blank">Privacy policy</a>.`,
    loginWithGoogleButton: "Login with Google",
    installTheAppTitle: "Install the App",
    installTheAppSubtitle: "to get a smoother experience",
    installButton: "Install",
    noAccessYet: "You don't have access to the app yet. If you know someone who's already on Hamo, ask them to share a (new) link"
  },
  homePage: {
    titleShort: "Home",
    title: "Welcome {{name}}",
    myItems: {
      title: "My items",
      requestedSentence: "{{count}} of my items have been requested",
      checkRequested: "Check the requests",
      totalItems: "I have {{count}} items in total",
      addMoreItems: "Add more items"
    },
    theirItems: {
      title: "Their items",
      requestedSentence: "I've requested {{count}} items",
      declinedSentence: "{{count}} of my requests were declined",
      viewRequested: "View the requests",
      viewDeclined: "View the declined requests",
      wishListSentence: "I have {{count}} items in my wishlist",
      viewWishlist: "View my wishlist"
    },
    connections: {
      title: "My connections",
      connectionsSentence: "I have {{count}} connections in Hamo",
      addMoreConnections: "Add more connections"
    },
    inbox: {
      title: "Items I'm exchanging",
      viewDetails: "View the details"
    }
  },
  placeholders: {
    search: 'Search'
  },
  menu: {
    more: "More",
    appTitle: "Hamo",
    logout: "Logout",
    install: "Install",
    languageSelected: "selected",
    languageSectionTitle: "Languages",
    pagesSectionTitle: "Pages",
    actionsSectionTitle: "Actions"
  },
  addItemPage: {
    title: "Add item(s)",
    shortTitle: "Add",
    toast: {
      onePhotoSuccess: "Successfully created an item based on your photo.",
      manyUploadSuccess: "Successfully created {{count}} items based on your photos.",
    },
    browse: "Browse your files",
    browseButton: "Select",
    browseMessage: "Each photo creates a new item, you can select many at once.",
    takeAPhoto: "Take a photo",
    takeAPhotoButton: "Shoot"
  },
  myHamoPage: {
    title: "My Hamo",
  },
  myConnectionsPage: {
    title: "My contacts",
    addContact: "Add contact",
    titleWithItemCount: "{{name}} has {{count}} items"
  },
  theirItemsPage: {
    title: "Their items",
    titleWithContact: "{{name}}'s items",
    titleWithStatus: {
      REQUESTED: "Requested items",
      REQUEST_DECLINED: "Declined requests",
      ACCEPTED: "Items to be handed over",
      ITEM_BORROWED: "Borrowed items",
      ITEM_GIFTED: "Received as gift",
      ITEM_CANNOT_BE_GIVEN_BACK: "Broken/lost items"
    },
    titleWithContactAndStatus: {
      REQUESTED: "Requested to {{name}}",
      REQUEST_DECLINED: "Requests declined by {{name}}",
      ACCEPTED: "To be handed over by {{name}}",
      ITEM_BORROWED: "Borrowed to {{name}}",
      ITEM_GIFTED: "Gifted by {{name}}",
      ITEM_CANNOT_BE_GIVEN_BACK: "Broken/lost owned by {{name}}"
    }
  },
  myItemsPage: {
    title: "My items",
    titleWithContact: "My items shared with {{name}}",
    titleWithStatus: {
      REQUESTED: "Requested items",
      ACCEPTED: "Items to be handed over",
      ITEM_BORROWED: "Borrowed items",
      ITEM_GIFTED: "Offered as gift",
      ITEM_CANNOT_BE_GIVEN_BACK: "Broken/lost items"
    },
    titleWithContactAndStatus: {
      REQUESTED: "Requested by {{name}}",
      ACCEPTED: "To be handed over to {{name}}",
      ITEM_BORROWED: "Handed over to {{name}}",
      ITEM_GIFTED: "Gifted to {{name}}",
      ITEM_CANNOT_BE_GIVEN_BACK: "Broken/lost by {{name}}"
    }
  },
  addContactPage: {
    title: "Add a contact",
    shareALink: "Share a link to add a contact",
    explainLinkSharing: `<p><b>Looking to grow your Hamo and share the joy of a less isolated parenting journey?</b></p>
<p>
Invite another parent to join you by:</p>
<ul>
<li>sending them this link, or</li>
<li>having them scan this QR code.</li>
</ul>
<p><b>
Note: Each link is unique, just like your future BFF!
To invite more people, click "create a new link" for each new contact.</b></p>`,
    getANewLink: "Get a new link",
    share: "Share",
    scan: "Scan",
    scanALink: "Scan a link",
    explainScanALink: "Let your friend open his Hamo app on this page and scan his link to connect.",
    shareLinkText: 'Connect with {{name}} on Hamo using this link.',
    shareLinkTitle: 'Connect on Hamo'
  },
  browsePage: {
    title: "Search",
    searchPlaceholder: "Search toys, books,..."
  },
  browseUserItemsPage: {
    title: "{{name}}'s items"
  },
  myItemPage: {
    titleShort: "My items",
    title: "All my items"
  },
  myItemDetailPage: {
    title: "My item",
    requestBy: "Request by {{name}}"
  },
  wishListPage: {
    title: "Wish list"
  },
  inboxPage: {
    title: "Inbox",
    myItems: "My items",
    theirItems: "Their items",
    myItemsMessage: {
      REQUESTED: "{{name}} is requesting {{count}} items",
      ACCEPTED: "I shall hand {{count}} item over to {{name}}",
      ITEM_BORROWED: "I've handed {{count}} over to {{name}}",
      ITEM_GIFTED: "I gave {{count}} items {{name}}",
      ITEM_CANNOT_BE_GIVEN_BACK: "{{name}} lost or broke {{count}} items"
    },
    theirItemsMessage: {
      REQUESTED: "I'm requesting {{count}} items from {{name}}",
      REQUEST_DECLINED: "{{name}} declined {{count}} requests of mine",
      ACCEPTED: "I shall check for {{name}} to hand hand {{count}} item over to me",
      ITEM_BORROWED: "I've borrowed {{count}} from {{name}}",
      ITEM_GIFTED: "I received as gifts {{count}} items from {{name}}",
      ITEM_CANNOT_BE_GIVEN_BACK: "I lost or broke {{count}} items of {{name}}"
    },
  },
  list: {
    noMoreItems: "No more items..."
  },
  enums: {
    requestStatus: {
      CREATED: "Request created",
      REQUESTED: "Item requested",
      ACCEPTED: "Request accepted",
      REQUEST_DECLINED: "Request declined",
      CANCELLED: "Request cancelled",
      ITEM_BORROWED: "Item borrowed",
      ITEM_GIFTED: "Item gifted",
      ITEM_GIVEN_BACK: "Item given back",
      ITEM_CANNOT_BE_GIVEN_BACK: "Item broken or lost"
    },
    plural: {
      requestStatus: {
        CREATED: "Requests created",
        REQUESTED: "Items requested",
        ACCEPTED: "Requests accepted",
        REQUEST_DECLINED: "Requests declined",
        CANCELLED: "Requests cancelled",
        ITEM_BORROWED: "Items borrowed",
        ITEM_GIFTED: "Items gifted",
        ITEM_GIVEN_BACK: "Items given back",
        ITEM_CANNOT_BE_GIVEN_BACK: "Items broken or lost"
      },
    },
    itemStatus: {
      HIDDEN: "Hidden",
      AVAILABLE: "Available",
      REQUESTED: "Requested",
      REQUEST_DECLINED: "Request declined",
      PENDING_DELIVERY: "Pending delivery",
      BORROWED: "Borrowed",
      BORROWED_BY_ME: "Borrowed by me",
      GIFTED_TO_ME: "Gifted to me",
      UNAVAILABLE: "Unavailable",
      GIFTED: "Gifted",
      LOST_BROKEN: "Lost or broken"
    },
    itemSharingDuration: {
      NEVER: "Hidden",
      SHORT_TERM: "Short term sharing (couple of days)",
      MEDIUM_TERM: "Medium term sharing (couple of weeks)",
      LONG_TERM: "Long term sharing (several months)",
      FOREVER: "Forever (gift)"
    }
  },
  actions: {
    clearSelection: "Unselect all",
    genericTitle: "Actions",
    noActionsTitle: "No actions for this item",
    noActionsForSelectionTitle: "No actions for the selected items",
    requester: {
      requestItem: "Request item",
      requestMultipleItems: "Request the {{count}} available item(s)",
      deleteDeclinedRequest: "Delete declined request",
      deleteMultipleDeclinedRequests: "Delete these {{count}} declined requests",
      cancelRequest: "Cancel my request",
      cancelMultipleRequests: "Cancel these {{count}} requests",
      cannotGiveBack: "I cannot give this item back"
    },
    owner: {
      acceptFrom: "Accept request from {{name}}",
      declineFrom: "Decline request from {{name}}",
      cancelFrom: "Cancel request from {{name}}",
      givenBackFrom: "Was given back by {{name}}",
      giftedTo: "Was gifted to {{name}}",
      cannotBeGivenBackFrom: "Cannot be given back by {{name}}",
      wasBorrowedBy: "Was borrowed by {{name}}",
      deleteItem: "Delete this item forever",
      deleteMultipleItems: "Delete these {{count}} items forever",
      updateDuration: "Update sharing duration",
      updateMultipleDuration: "Update sharing duration for these {{count}} items"
    }
  },
  date: {
    itemRequested: "Item requested",
    modified: "updated",
    onDay: "on",
    atTime: "at"
  },
  deleteItemModal: {
    delete: "Delete",
    dontDelete: "Don't delete",
    sentenceMultiple: "Are you sure you want to delete these {{count}} items forever ?",
    sentenceSingle: "Are you sure you want to delete this item forever ?",
    noCancel: "Caution: this operation cannot be cancelled."
  },
  updateDurationModal: {
    dontUpdate: "Don't update",
    update: "Update"
  }
};
