import {NgModule} from "@angular/core";
import {I18nPipe} from "./i18n.pipe";
import {SignalPipe} from "./signal.pipe";
import {CommonModule} from "@angular/common";
import {I18nUnsafePipe} from "./i18n-unsafe.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [
    I18nPipe,
    I18nUnsafePipe,
    SignalPipe
  ],
  providers: [],
  exports: [
    I18nPipe,
    I18nUnsafePipe,
    SignalPipe
  ]
})
export class HamoI18nModule {
}
