import {Injectable, signal, WritableSignal} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IdentityService {

  userName: WritableSignal<string> = signal('');
  admin: WritableSignal<boolean> = signal(false);

  constructor() { }
}
