export * from './admin.service';
import { AdminService } from './admin.service';
export * from './contact.service';
import { ContactService } from './contact.service';
export * from './home.service';
import { HomeService } from './home.service';
export * from './inbox.service';
import { InboxService } from './inbox.service';
export * from './item.service';
import { ItemService } from './item.service';
export * from './metrics.service';
import { MetricsService } from './metrics.service';
export * from './request.service';
import { RequestService } from './request.service';
export * from './whoami.service';
import { WhoamiService } from './whoami.service';
export const APIS = [AdminService, ContactService, HomeService, InboxService, ItemService, MetricsService, RequestService, WhoamiService];
