import { Pipe, PipeTransform, Signal } from '@angular/core';

@Pipe({
  name: 'signal',
  pure: false,
})
export class SignalPipe implements PipeTransform {
  transform<T>(value: Signal<T>): T {
    return value();
  }
}
