<div style="display: flex;flex-direction: column;" (click)="menuController.close()">
  <ion-list>
    <ion-item [button]="true" routerLinkActive="active" routerLink="/" [routerLinkActiveOptions]="{exact: true}">
      <ion-label>
        {{ 'homePage.titleShort' | i18n | signal }}
      </ion-label>
    </ion-item>


    <ion-item [button]="true" routerLinkActive="active" routerLink="/browse-item">
      <ion-label>
        {{ 'browsePage.title' | i18n | signal }}
      </ion-label>
    </ion-item>
    <ion-item class="indent-1" routerLinkActive="active" routerLink="/browse-item/my-wish-list"
              data-test="wish-list-link">
      <!--              <ion-icon aria-hidden="true" slot="start" name="star-outline"></ion-icon>-->
      <ion-label>{{ 'wishListPage.title' | i18n | signal }}</ion-label>
    </ion-item>
    <!--               <ion-item class="indent-1">Browse user x items</ion-item>-->
    <!--                  <ion-item class="indent-2">Browse item x of user x</ion-item>-->


    <ion-item [button]="true" routerLinkActive="active" routerLink="/inbox">
      <ion-label>{{ 'inboxPage.title' | i18n | signal }}</ion-label>
    </ion-item>
    <!--                <ion-item class="indent-1">Inbox with user XXX</ion-item>-->
    <ion-item class="indent-1" [button]="true" routerLinkActive="active" routerLink="/inbox/my-items">
      <ion-label>{{ 'myItemsPage.title' | i18n | signal }}</ion-label>
    </ion-item>
    <!--                    <ion-item class="indent-2">Inbox as owner with status XXX</ion-item>-->
    <ion-item class="indent-1" [button]="true" routerLinkActive="active" routerLink="/inbox/their-items">
      <ion-label>{{ 'theirItemsPage.title' | i18n | signal }}</ion-label>
    </ion-item>
    <!--                    <ion-item class="indent-2">Inbox as requester with status XXX</ion-item>-->
    <ion-item [button]="true" routerLinkActive="active" routerLink="/my-item">
      <ion-label>
        {{ 'myItemPage.title' | i18n | signal }}
      </ion-label>
    </ion-item>
    <ion-item class="indent-1" [button]="true" routerLinkActive="active" routerLink="/my-item/add-item">
      <ion-label>
        {{ 'addItemPage.title' | i18n | signal }}
      </ion-label>
    </ion-item>
    <!--               <ion-item class="indent-1">My item detail</ion-item>-->
    <ion-item [button]="true" routerLinkActive="active" routerLink="/my-hamo">
      <ion-label>
        {{ 'myConnectionsPage.title' | i18n | signal }}
      </ion-label>
    </ion-item>
    <ion-item class="indent-1" [button]="true" routerLinkActive="active" routerLink="/my-hamo/add">
      <ion-label>
        {{ 'addContactPage.title' | i18n | signal }}
      </ion-label>
    </ion-item>
    <!--               <ion-item class="indent-1">Inbox with user XXX</ion-item>-->
    <!--                  <ion-item class="indent-2">Browse user x items</ion-item>-->
    <!--                     <ion-item class="indent-3">Browse item x of user x</ion-item>-->
    <!--                  <ion-item class="indent-2">My item detail</ion-item>-->
    <!--                  <ion-item class="indent-2">His item detail</ion-item>-->
  </ion-list>
  <ion-list>
    <ion-list-header>
      <ion-label>{{ 'menu.languageSectionTitle' | i18n | signal }}</ion-label>
    </ion-list-header>
    @for (language of LanguageService.langList; track language) {
      <ion-item [button]="true" [disabled]="language === languageService.currentLang$$()"
                [attr.data-test]="'language-'+language"
                (click)="languageService.changeLang(language)">

        {{ LanguageService.langNames[language] }}
        @if (language === languageService.currentLang$$()) {
          ({{ 'menu.languageSelected' | i18n | signal }})
        }
      </ion-item>
    }
  </ion-list>
  <ion-list>
    <ion-list-header>
      <ion-label>{{ 'menu.actionsSectionTitle' | i18n | signal }}</ion-label>
    </ion-list-header>
    @if (pwaInstallService.shouldSuggestInstall()) {
      <ion-item [button]="true" (click)="pwaInstallService.installPwa()">
        <ion-icon name="download-outline" slot="start"></ion-icon>
        <ion-label>{{ 'menu.install' | i18n | signal }}</ion-label>
      </ion-item>
    }
    <form action="/logout" method="POST" #logoutForm></form>
    <ion-item [button]="true" (click)="logoutForm.submit()" data-test="logout-button">
      <ion-icon name="exit-outline" slot="start"></ion-icon>
      <ion-label>{{ 'menu.logout' | i18n | signal }}</ion-label>
    </ion-item>
  </ion-list>
  @if (identityService.admin()) {
    <ion-list>
      <ion-list-header>
        <ion-label>Admin stuff</ion-label>
      </ion-list-header>
      <ion-item class="indent-1" [button]="true" routerLinkActive="active" routerLink="/metrics">
        <ion-label>
          Metrics
        </ion-label>
      </ion-item>
      <ion-item class="indent-1" [button]="true" routerLinkActive="active" routerLink="/admin">
        <ion-label>
          Admin
        </ion-label>
      </ion-item>
    </ion-list>
  }
</div>
