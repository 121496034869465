import {Component, SecurityContext, signal, WritableSignal} from '@angular/core';
import {LanguageService} from "../../modules/i18n/language.service";
import {PwaInstallService} from "../../services/pwa-install.service";
import {DomSanitizer} from "@angular/platform-browser";
import {CookieService} from "ngx-cookie-service";
import {ContactService, DescribeInvitationResponse} from "../../../openapi-generated";
import {createHttpState, HttpStateWrapper} from "../../services/http-state.service";

import { IonicSlides } from '@ionic/angular';


@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  providers: [CookieService],
})
export class LoginPageComponent {

  public invitationState: WritableSignal<HttpStateWrapper<DescribeInvitationResponse>> = signal(createHttpState());

  constructor(public pwaInstallService: PwaInstallService, public languageService: LanguageService, public domSanitizer: DomSanitizer, private cookieService: CookieService, private contactService: ContactService) {
    const invitationId = this.cookieService.get('invitation');
    if(invitationId){
       this.contactService.describeInvitation(invitationId).subscribe(state => this.invitationState.set(state));
    }

  }


  protected readonly LanguageService = LanguageService;
  protected readonly SecurityContext = SecurityContext;
  swiperModules = [IonicSlides];
}
