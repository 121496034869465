import {Injectable, signal} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PwaInstallService {


  private promptEvent: any;
  shouldSuggestInstall = signal(false);

  constructor() { }

  public initPwaPrompt() {
    window.addEventListener('beforeinstallprompt', (event: any) => {
      event.preventDefault();
      this.promptEvent = event;
      this.shouldSuggestInstall.set(true);
    });
  }


  public installPwa() {
    this.shouldSuggestInstall.set(false);
    this.promptEvent.prompt();
    this.promptEvent.userChoice.then((choiceResult: any) => {
      console.log(choiceResult);
      this.promptEvent = null;
    });
  }
}
