import {Component, effect, signal, WritableSignal} from '@angular/core';
import {WhoamiResponse, WhoamiService} from "../openapi-generated";
import {PwaInstallService} from "./services/pwa-install.service";
import {LanguageService} from "./modules/i18n/language.service";
import {createHttpState, HttpStateWrapper} from "./services/http-state.service";
import {MenuController} from "@ionic/angular";
import {IdentityService} from "./modules/i18n/hamo-common/identity.service";

import * as Sentry from "@sentry/angular";

enum LoginStatus {
  DontKnowYet,
  LoggedOut,
  LoggedIn,
  Unauthorized
}

import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {


  loginStatus: WritableSignal<LoginStatus> = signal(LoginStatus.DontKnowYet);

  whoami: WritableSignal<HttpStateWrapper<WhoamiResponse>> = signal(createHttpState());

  constructor(private whoamiService: WhoamiService, public pwaInstallService: PwaInstallService, public languageService: LanguageService, public menuController: MenuController, private identityService: IdentityService) {
    effect(() => {
        if (this.whoami().isReady) {
          const it = this.whoami();

          if (it.data?.authenticated && it.data!.sentryEnabled) {
            Sentry.init({
              dsn: it.data.sentryDsn,
              defaultIntegrations: false,
              integrations: [
                Sentry.breadcrumbsIntegration(),
                Sentry.browserApiErrorsIntegration(),
                Sentry.functionToStringIntegration(),
                Sentry.globalHandlersIntegration(),
                Sentry.httpContextIntegration(),
                Sentry.inboundFiltersIntegration(),
                Sentry.httpClientIntegration()
              ],
              sendDefaultPii: true
            });
            Sentry.setContext("user_email", {email: it.data.email});
          }

          const whoami = this.whoami().data!;
          if (whoami.authenticated && whoami.authorized) {
            this.identityService.userName.set(whoami.email!);
            this.identityService.admin.set(whoami.admin);
            this.loginStatus.set(LoginStatus.LoggedIn)
          } else if (whoami.authenticated && !whoami.authorized) {
            this.loginStatus.set(LoginStatus.Unauthorized)
          } else {
            this.loginStatus.set(LoginStatus.LoggedOut)
          }
        }
      }, {allowSignalWrites: true}
    );
    this.whoami.set(createHttpState());
    whoamiService.apiWhoamiGet().subscribe(state => {
        this.whoami.set(state);
      }
    );
  }

  canInstall: WritableSignal<boolean> = signal(false);

  protected readonly LoginStatus = LoginStatus;

  protected readonly LanguageService = LanguageService;
}
