import {I18NAppResource} from "./i18n-types";


export const i18nNl: I18NAppResource = {
  error: {
    message: 'Er is een probleem ...'
  },
  messages: {
    noItemFound: "Geen item gevonden..."
  },
  home: {
    welcomeTitle: "Welkom bij Hamo",
    welcomeSubtitle: "Waar speelgoed delen zorgt voor duurzame vriendschappen",
    welcomeInvitationSubtitle: "{{name}} ({{email}}) has invited you to their community",
    pleaseLogin: `Door u aan te melden, gaat u akkoord met onze <a href="https://about.hamo.app/nl/termsofuse" target="_blank">gebruiksvoorwaarden</a> en ons <a href="https://about.hamo.app/nl/privacypolicy" target="_blank">privacybeleid</a>`,
    loginWithGoogleButton: "Inloggen met Google",
    installTheAppTitle: "Installeer de app",
    installTheAppSubtitle: "voor een vlottere ervaring",
    installButton: "Installeren",
    noAccessYet: "Je hebt nog geen toegang tot de app. Als je iemand kent die Hamo gebruikt, vraag dan om een (nieuwe) link te delen"
  },
  homePage: {
    titleShort: "Start",
    title: "Welkom {{name}}",
    myItems: {
      title: "Jouw items",
      requestedSentence: "{{count}} van je items zijn aangevraagd",
      checkRequested: "Bekijk de aanvragen",
      totalItems: "Je hebt in totaal {{count}} items",
      addMoreItems: "Voeg meer items toe"
    },
    theirItems: {
      title: "Hun items",
      requestedSentence: "Je hebt {{count}} items aangevraagd",
      declinedSentence: "{{count}} van mijn aanvragen zijn geweigerd",
      viewRequested: "Bekijk de aanvragen",
      viewDeclined: "Bekijk de geweigerd aanvragen",
      wishListSentence: "Je hebt {{count}} items in je verlanglijst",
      viewWishlist: "Bekijk je verlanglijst"
    },
    connections: {
      title: "Jouw connecties",
      connectionsSentence: "Je hebt {{count}} connecties in Hamo",
      addMoreConnections: "Voeg meer connecties toe"
    },
    inbox: {
      title: "Items die je aan het lenen of delen bent",
      viewDetails: "Bekjik de details"
    }
  },
  placeholders: {
    search: 'Zoeken'
  },
  menu: {
    more: "Meer",
    appTitle: "Hamo",
    logout: "Uitloggen",
    install: "Installeren",
    languageSelected: "geselecteerd",
    pagesSectionTitle: "Pagina's",
    languageSectionTitle: "Talen",
    actionsSectionTitle: "Acties"
  },
  addItemPage: {
    title: "Voeg items toe",
    shortTitle: 'Toevoegen',
    toast: {
      onePhotoSuccess: "Een item is succesvol aangemaakt op basis van je foto.",
      manyUploadSuccess: "{{count}} item(s) aangemaakt op basis van je foto's",
    },
    browse: "Blader in jouw bestanden",
    browseButton: "Kiezen",
    browseMessage: "Elke foto maakt een nieuw item, je kan verschillende items uploaden in een keer.",
    takeAPhoto: "Neem een foto",
    takeAPhotoButton: "Klik"
  },
  myHamoPage: {
    title: "Mijn Hamo",
  },
  myConnectionsPage: {
    title: "Mijn contacts",
    addContact: "Voeg een contact toe",
    titleWithItemCount: "{{name}} has {{count}} items"
  },
  theirItemsPage: {
    title: "Hun items",
    titleWithContact: "Items van {{name}}",
    titleWithStatus: {
      REQUESTED: "Requested items",
      REQUEST_DECLINED: "Geweigerd aanvraag",
      ACCEPTED: "Over te dragen items",
      ITEM_BORROWED: "Geleende items",
      ITEM_GIFTED: "Als cadeau gekregen",
      ITEM_CANNOT_BE_GIVEN_BACK: "Kapot/verloren items"
    },
    titleWithContactAndStatus: {
      REQUESTED: "Aangevraagd door {{name}}",
      REQUEST_DECLINED: "Aanvraag geweigerd door {{name}}",
      ACCEPTED: "Over te dragen door {{name}}",
      ITEM_BORROWED: "Gedeeld aan {{name}}",
      ITEM_GIFTED: "Gegeven als cadeau aan{{name}}",
      ITEM_CANNOT_BE_GIVEN_BACK: "Kapot/verloren items van {{name}}"
    }
  },
  myItemsPage: {
    title: "Mijn items",
    titleWithContact: "Mijn items gedeeld met {{name}}",
    titleWithStatus: {
      REQUESTED: "Aangevraagd items",
      ACCEPTED: "Over te dragen items",
      ITEM_BORROWED: "Geleende items",
      ITEM_GIFTED: "Als cadeau gegeven",
      ITEM_CANNOT_BE_GIVEN_BACK: "Kapot/verloren items"
    },
    titleWithContactAndStatus: {
      REQUESTED: "Aangevraagd door {{name}}",
      ACCEPTED: "Over te dragen aan {{name}}",
      ITEM_BORROWED: "Overgedragen aan {{name}}",
      ITEM_GIFTED: "Gegeven als cadeau aan {{name}}",
      ITEM_CANNOT_BE_GIVEN_BACK: "Broken/lost door {{name}}"
    }
  },
  addContactPage: {
    title: "Connectie toevoegen",
    shareALink: "Deel een link om een contact toe te voegen",
    explainLinkSharing: `<p><b>Zin om je Hamo gemeenschap uit te breiden om samen te bouwen aan een gemeenschap van ouders?</b></p>
<p>
Nodig andere ouders uit door :</p>
<ul>
<li>deze link te sturen</li>
<li>of deze QR-code te scannen </li>
</ul>
<p><b>
Pas op: elke link is uniek, net zoals je relatie met je nieuwe BFF!
Je kan andere mensen uitnodigen door te klikken op “Maak een nieuwe link” per nieuwe contactpersoon die je toevoegt.</b></p>`,
    getANewLink: "Maak een nieuwe link",
    share: "Delen",
    explainScanALink: "Vraag je contact om de Hamo-app op dit scherm te openen en scan hun QR-code",
    scanALink: "Scan een link",
    scan: "Scannen",
    shareLinkText: 'Verbind met {{name}} op Hamo via deze link.',
    shareLinkTitle: 'Verbind op Hamo'
  },
  browsePage: {
    title: "Zoeken",
    searchPlaceholder: "Zoek een speelgoed, boek,..."
  },
  browseUserItemsPage: {
    title: "Items van  {{name}}"
  },
  myItemPage: {
    titleShort: "Mijn items",
    title: "Al mijn items"
  },
  myItemDetailPage: {
    title: "Mijn item",
    requestBy: "Aanvraag van {{name}}"
  },
  wishListPage: {
    title: "Verlanglijst"
  },
  inboxPage: {
    title: "Inbox",
    myItems: "Mijn items",
    theirItems: "Hun items",
    myItemsMessage: {
      REQUESTED: "{{name}} vraagt {{count}} items aan",
      ACCEPTED: "Ik ga {{count}} item(s) overdragen aan {{name}}",
      ITEM_BORROWED: "Ik heb {{count}} item geleend aan {{name}}",
      ITEM_GIFTED: "Ik heb {{count}} items als cadeau gegeven aan {{name}}",
      ITEM_CANNOT_BE_GIVEN_BACK: "{{name}} heeft {{count}} items verloren of kapot gemaakt"
    },
    theirItemsMessage: {
      REQUESTED: "Ik vraag {{count}} items aan van {{name}}",
      REQUEST_DECLINED: "{{name}} heeft {{count}} van mijn aanvragen geweigerd",
      ACCEPTED: "Ik neem contact op met {{name}} om te regelen dat ik {{count}} item(s) leen",
      ITEM_BORROWED: "Ik heb {{count}} item(s) geleend van {{name}}",
      ITEM_GIFTED: "Ik heb {{count}} item(s) als cadeau gekregen van {{name}}",
      ITEM_CANNOT_BE_GIVEN_BACK: "Ik heb {{count}} item(s) van {{name}} verloren of kapot gemaakt"
    },
  },
  list: {
    noMoreItems: "Geen andere resultaten..."
  },
  enums: {
    requestStatus: {
      CREATED: "Aanvraag aangemaakt",
      REQUESTED: "Item aangevraagd",
      ACCEPTED: "Aanvraag geaccepteerd",
      REQUEST_DECLINED: "Aanvraag geweigerd",
      CANCELLED: "Aanvraag geannuleerd",
      ITEM_BORROWED: "Item geleend",
      ITEM_GIFTED: "Item gegeven",
      ITEM_GIVEN_BACK: "Item teruggegeven",
      ITEM_CANNOT_BE_GIVEN_BACK: "Item gebroken of verloren"
    },
    plural: {
      requestStatus: {
        CREATED: "Aanvragen aangemaakt",
        REQUESTED: "Items aangevraagd",
        ACCEPTED: "Aanvragen geaccepteerd",
        REQUEST_DECLINED: "Aanvragen geweigerd",
        CANCELLED: "Aanvragen geannuleerd",
        ITEM_BORROWED: "Item geleend",
        ITEM_GIFTED: "Item als cadeau gegeven",
        ITEM_GIVEN_BACK: "Item teruggegeven",
        ITEM_CANNOT_BE_GIVEN_BACK: "Verloren of kapot gemaakt item"
      }
    },
    itemStatus: {
      HIDDEN: "Verborgen",
      AVAILABLE: "Beschikbaar",
      REQUESTED: "Aangevraagd",
      REQUEST_DECLINED: "Aanvraag geweigerd",
      PENDING_DELIVERY: "Moet worden overgedragen",
      BORROWED: "Geleend",
      BORROWED_BY_ME: "Geleend door mij",
      GIFTED_TO_ME: "Als cadeau ontvangen",
      UNAVAILABLE: "Niet beschikbaar",
      GIFTED: "Gegeven als cadeau",
      LOST_BROKEN: "Gebroken of verloren"
    },
    itemSharingDuration: {
      NEVER: "Verborgen",
      SHORT_TERM: "Korte sharing (enkele dagen)",
      MEDIUM_TERM: "Middellang sharing (enkele weken)",
      LONG_TERM: "Lange sharing (meerdere maanden)",
      FOREVER: "Voor altijd (cadeau)"
    }
  },
  actions: {
    clearSelection: "Alles deselecteren",
    noActionsForSelectionTitle: "Geen acties voor deze items",
    genericTitle: "Acties",
    noActionsTitle: "Geen acties voor dit item",
    requester: {
      requestItem: "Vraag dit item aan",
      cancelRequest: "Annuleer mijn aanvraag",
      cannotGiveBack: "Dit item kan niet worden teruggegeven",
      cancelMultipleRequests: "Annuleer deze aanvragen",
      requestMultipleItems: "Vraag deze items aan",
      deleteDeclinedRequest: "Weigerde aanvraag verwijderen",
      deleteMultipleDeclinedRequests: "Verwijder deze {{count}} weigerde aanvragen",
    },
    owner: {
      acceptFrom: "Accepteer aanvraag van {{name}}",
      declineFrom: "Weiger  aanvraag van {{name}}",
      cancelFrom: "Annuleer  aanvraag van {{name}}",
      givenBackFrom: "Is teruggegeven door {{name}}",
      giftedTo: "Is gegeven als cadeau aan {{name}}",
      cannotBeGivenBackFrom: "Kan niet worden teruggegeven door {{name}}",
      wasBorrowedBy: "Is geleend door {{name}}",
      deleteItem: "Dit item definitief verwijderen",
      deleteMultipleItems: "Definitief verwijder deze {{count}} items",
      updateDuration: "Update sharing duration",
      updateMultipleDuration: "Update sharing duration for these {{count}} items"
    }
  },
  date: {
    atTime: 'om',
    onDay: 'op',
    itemRequested: "Item aangevraagd",
    modified: "gewijzigd"
  },
  deleteItemModal: {
    delete: "Verwijderen",
    dontDelete: "Niet verwijderen",
    sentenceMultiple: "Weet je zeker dat je deze {{count}} items definitief wilt verwijderen?",
    sentenceSingle: "Weet je zeker dat je dit item wilt verwijderen?",
    noCancel: "Let op: deze actie kan niet ongedaan worden gemaakt."
  },
  updateDurationModal: {
    dontUpdate: "Don't update",
    update: "Update"
  }
};





