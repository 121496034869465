import { Component, OnInit } from '@angular/core';
import {LanguageService} from "../../modules/i18n/language.service";
import {PwaInstallService} from "../../services/pwa-install.service";
import {MenuController} from "@ionic/angular";
import {IdentityService} from "../../modules/i18n/hamo-common/identity.service";

@Component({
  selector: 'app-hamo-menu',
  templateUrl: './hamo-menu.component.html',
  styleUrls: ['./hamo-menu.component.scss'],
})
export class HamoMenuComponent {

  constructor(public pwaInstallService: PwaInstallService, public languageService: LanguageService, public menuController: MenuController, public identityService: IdentityService) { }

  protected readonly LanguageService = LanguageService;
}
