<ion-grid style="max-width: 576px">
  <ion-row>
    <ion-col>
      <ion-card>
        @for (language of LanguageService.langList; track language) {
          <ion-button [attr.data-test]="'language-'+language"
                      [disabled]="language === languageService.currentLang$$()" fill="clear"
                      (click)="languageService.changeLang(language)">
            {{ LanguageService.langNames[language] }}
            @if (language === languageService.currentLang$$()) {
              ({{ 'menu.languageSelected' | i18n | signal }})
            }
          </ion-button>
        }
      </ion-card>
      <ion-card>
        <ion-card-header>
          <ion-card-title>{{ 'home.welcomeTitle' | i18n | signal }}</ion-card-title>
          @if (invitationState().isReady) {
            @if (invitationState().data; as d) {
              <ion-card-subtitle>
                {{ 'home.welcomeInvitationSubtitle' | i18n:{name: d.userName, email: d.userEmail} | signal }}
              </ion-card-subtitle>
            }
          } @else {
            <ion-card-subtitle>{{ 'home.welcomeSubtitle' | i18n | signal }}</ion-card-subtitle>
          }
        </ion-card-header>


        <ion-card-content
          [innerHTML]="domSanitizer.sanitize(SecurityContext.HTML, 'home.pleaseLogin' | i18n | signal )">

        </ion-card-content>
        <ion-card-content>
          <app-google-login-button></app-google-login-button>
        </ion-card-content>

      </ion-card>



      @if (pwaInstallService.shouldSuggestInstall()) {
        <ion-card>
          <ion-card-header>
            <ion-card-title>{{ 'home.installTheAppTitle' | i18n | signal }}</ion-card-title>
            <ion-card-subtitle>{{ 'home.installTheAppSubtitle' | i18n | signal }}</ion-card-subtitle>
          </ion-card-header>
          <ion-button fill="clear"
                      (click)="pwaInstallService.installPwa()">{{ 'home.installButton' | i18n | signal }}
          </ion-button>
        </ion-card>
      }
      <swiper-container [modules]="swiperModules" autoplay-delay="2500" autoplay-disable-on-interaction="true"
                        [pagination]="true">
        @for (i of [1, 2, 3, 4, 5]; track i) {
          <swiper-slide>
            <ion-card style="margin-top: 0;">
              <img style="display: block" [src]="'/carousel/'+languageService.currentLang$$()+'/'+i+'.png'"/>
            </ion-card>
          </swiper-slide>
        }
      </swiper-container>
    </ion-col>
  </ion-row>
</ion-grid>
