/**
 * Hamo contract
 * Hamo contract
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ItemStatus = 'HIDDEN' | 'AVAILABLE' | 'REQUESTED' | 'REQUEST_DECLINED' | 'PENDING_DELIVERY' | 'BORROWED' | 'BORROWED_BY_ME' | 'GIFTED_TO_ME' | 'UNAVAILABLE' | 'GIFTED' | 'LOST_BROKEN';

export const ItemStatus = {
    Hidden: 'HIDDEN' as ItemStatus,
    Available: 'AVAILABLE' as ItemStatus,
    Requested: 'REQUESTED' as ItemStatus,
    RequestDeclined: 'REQUEST_DECLINED' as ItemStatus,
    PendingDelivery: 'PENDING_DELIVERY' as ItemStatus,
    Borrowed: 'BORROWED' as ItemStatus,
    BorrowedByMe: 'BORROWED_BY_ME' as ItemStatus,
    GiftedToMe: 'GIFTED_TO_ME' as ItemStatus,
    Unavailable: 'UNAVAILABLE' as ItemStatus,
    Gifted: 'GIFTED' as ItemStatus,
    LostBroken: 'LOST_BROKEN' as ItemStatus
};

