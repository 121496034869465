import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpProgressEvent,
  HttpResponse,
} from '@angular/common/http';
import {ErrorHandler, Injectable, Injector, signal, WritableSignal} from '@angular/core';
import { Observable, ReplaySubject, Subscription, defer, finalize } from 'rxjs';

import { AppAvailabilityService } from './app-availability.service';
import { VersionCheckService } from './version-check.service';

export enum HttpStateStatus {
  NOT_STARTED = 'NOT_STARTED',
  LOADING = 'LOADING',
  READY = 'READY',
  ERROR = 'ERROR',
}

export interface HttpStateWrapper<T> {
  status: HttpStateStatus;

  isLoading: boolean;
  isReady: boolean;
  isError: boolean;
  error?: HttpErrorResponse;
  data?: T;
  headers: HttpHeaders;
  progress: number;
}

class HttpStateWrapperImpl<T> implements HttpStateWrapper<T> {
  _status: HttpStateStatus = HttpStateStatus.NOT_STARTED;

  _rawError: any;
  _data?: T;
  _headers?: HttpHeaders;
  progress = 0;

  get data(): T {
    return this._data!;
  }

  set data(d: T){
    this._data = d;
  }

  get headers(): HttpHeaders {
    if (this._headers) {
      return this._headers;
    }
    return new HttpHeaders();
  }

  get status(): HttpStateStatus {
    return this._status;
  }

  get isError(): boolean {
    return this._status === HttpStateStatus.ERROR;
  }

  get isLoading(): boolean {
    return this._status === HttpStateStatus.LOADING;
  }

  get isReady(): boolean {
    return this._status === HttpStateStatus.READY;
  }

  get error(): HttpErrorResponse {
    if (this._rawError && this._rawError instanceof HttpErrorResponse) {
      return this._rawError;
    }
    return new HttpErrorResponse(this._rawError);
  }
}

export function createHttpState(): HttpStateWrapper<any> {
  const r = new HttpStateWrapperImpl();
  r._status = HttpStateStatus.NOT_STARTED;
  r.progress = 0;
  return r;
}

export function createHttpStateReady<T>(event: HttpResponse<T>): HttpStateWrapper<T> {
  const r = new HttpStateWrapperImpl<T>();
  r._data = event.body!;
  r._headers = event.headers;
  r._status = HttpStateStatus.READY;
  r.progress = 1;
  return r;
}

export function createHttpStateLoading(progress?: number): HttpStateWrapper<any> {
  const r = new HttpStateWrapperImpl();
  r._status = HttpStateStatus.LOADING;
  r.progress = progress ? progress : 0;
  return r;
}

export function createHttpStateError<T>(error: any): HttpStateWrapper<any> {
  const r = new HttpStateWrapperImpl();
  r._status = HttpStateStatus.ERROR;
  r._rawError = error;
  r.progress = 0;
  return r;
}

export function doOnSubscribe<T>(onSubscribe: () => void): (source: Observable<T>) => Observable<T> {
  return function inner(source: Observable<T>): Observable<T> {
    return defer(() => {
      onSubscribe();
      return source;
    });
  };
}

@Injectable({
  providedIn: 'root',
})
export class HttpStateService {
  public flushCount = signal(0);

  public inFlightCount: WritableSignal<number> = signal(0);

  constructor(
    private injector: Injector,
    private appAvailabilityService: AppAvailabilityService,
    private versionCheckService: VersionCheckService,
  ) {}

  executeQuery<ResponseType>(query: Observable<HttpEvent<ResponseType>>): Observable<HttpStateWrapper<ResponseType>> {
    const errorForStack = new Error();
    const subject = new ReplaySubject<HttpStateWrapper<ResponseType>>();
    subject.next(createHttpStateLoading());
    let sub: Subscription | undefined = undefined;
    return subject
      .asObservable()
      .pipe(
        doOnSubscribe(() => {
          this.inFlightCount.set(this.inFlightCount()+1);
          sub = query.subscribe({
            next: (event: HttpEvent<ResponseType>) => {
              subject.next(this.makeStateFromEvent(event));
              if (event.type === HttpEventType.Response) {
                this.appAvailabilityService.setStatus(true);
                this.versionCheckService.notifyVersion(event.headers.get('x-version'));

                this.inFlightCount.set(this.inFlightCount()-1);
                subject.complete();
              }
            },
            error: (error) => {
              if (error instanceof HttpErrorResponse) {
                if ([502, 503, 504].indexOf(error.status) > -1) {
                  this.appAvailabilityService.setStatus(false);
                }
                if(error.status === 401) {
                  window.location.reload();
                }
              }
              (this.injector.get(ErrorHandler)).handleError(error);
              subject.next(createHttpStateError(error));
              this.inFlightCount.set(this.inFlightCount()-1);
              subject.complete();
            },
          });
        }),
      )
      .pipe(
        finalize(() => {
          sub?.unsubscribe();
        }),
      );
  }

  private makeStateFromEvent<ResponseType>(event: HttpEvent<ResponseType>): HttpStateWrapper<ResponseType> {
    switch (event.type) {
      case HttpEventType.User:
        return createHttpStateLoading();
      case HttpEventType.UploadProgress:
        return createHttpStateLoading(this.computeProgress(event));
      case HttpEventType.Sent:
        return createHttpStateLoading();
      case HttpEventType.ResponseHeader:
        return createHttpStateLoading();
      case HttpEventType.Response:
        return createHttpStateReady(event);
      case HttpEventType.DownloadProgress:
        return createHttpStateLoading(this.computeProgress(event));
    }
  }

  private computeProgress(event: HttpProgressEvent) {
    return event.total ? event.loaded / event.total : 0.5;
  }
}
