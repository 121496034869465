import {I18NAppResource} from "./i18n-types";

export const i18nFr: I18NAppResource = {
  error: {
    message: 'Il y a eu un problème ...'
  },
  messages: {
    noItemFound: "Pas d'objet trouvé..."
  },
  home: {
    welcomeTitle: "Bienvenue sur Hamo",
    welcomeSubtitle: "Où partager des jouets crée des liens pour la vie",
    welcomeInvitationSubtitle: "{{name}} ({{email}}) vous a invité dans sa communauté",
    pleaseLogin: `En vous connectant, vous acceptez nos <a href="https://about.hamo.app/en/termsofuse" target="_blank">Conditions d'utilisation</a> et notre <a href="https://about.hamo.app/en/privacypolicy" target="_blank">Politique de confidentialité</a>.`,
    loginWithGoogleButton: "Se connecter avec Google",
    installTheAppTitle: "Installer l'app",
    installTheAppSubtitle: "pour une expérience plus fluide",
    installButton: "Installer",
    noAccessYet: "Vous n'avez pas encore accès à l'app. Si vous connaissez quelqu'un qui utilise Hamo, demandez-lui de vous partager un (nouveau) lien"
  },
  homePage: {
    titleShort: "Accueil",
    title: "Bonjour {{name}}",
    myItems: {
      title: "Mes objets",
      requestedSentence: "{{count}} de mes objets ont été demandés",
      checkRequested: "Voir les demandes",
      totalItems: "J'ai {{count}} objets au total",
      addMoreItems: "Ajouter des objets"
    },
    theirItems: {
      title: "Leurs objets",
      requestedSentence: "J'ai demandé {{count}} objets",
      declinedSentence: "{{count}} de mes demandes ont été refusées",
      viewRequested: "Voir les demandes",
      viewDeclined: "Voir les demandes refusées",
      wishListSentence: "J'ai {{count}} objets dans mes favoris",
      viewWishlist: "Voir mes favoris"
    },
    connections: {
      title: "Mes contacts",
      connectionsSentence: "J'ai {{count}} contacts dans Hamo",
      addMoreConnections: "Ajouter des contacts"
    },
    inbox: {
      title: "Les objets que j'échange pour l'instant",
      viewDetails: "Voir les détails"
    }
  },
  placeholders: {
    search: 'Chercher'
  },
  menu: {
    more: "Plus",
    appTitle: "Hamo",
    logout: "Se déconnecter",
    install: "Installer",
    languageSelected: "sélectionné",
    pagesSectionTitle: "Pages",
    languageSectionTitle: "Langues",
    actionsSectionTitle: "Actions"
  },
  addItemPage: {
    title: "Ajouter des objets",
    shortTitle: 'Ajouter',
    toast: {
      onePhotoSuccess: "Un objet a été créé avec succès sur base de cette photo.",
      manyUploadSuccess: "{{count}} objets créés sur base de ces photos.",
    },
    browse: "Parcourir vos images",
    browseButton: "Choisir",
    browseMessage: "Chaque photo crée un nouvel objet, vous pouvez en choisir plusieurs en une seule fois.",
    takeAPhoto: "Prendre une photo",
    takeAPhotoButton: "Clic"
  },
  myHamoPage: {
    title: "Mon Hamo",
  },
  myConnectionsPage: {
    title: "Mes contacts",
    addContact: "Ajouter un contact",
    titleWithItemCount: "{{name}} a {{count}} objets"
  },
  theirItemsPage: {
    title: "Leurs objets",
    titleWithContact: "Objets de {{name}}",
    titleWithStatus: {
      REQUESTED: "Objets demandés",
      REQUEST_DECLINED: "Demandes refusées",
      ACCEPTED: "Objets à recevoir",
      ITEM_BORROWED: "Objets empruntés",
      ITEM_GIFTED: "Objets reçus en cadeau",
      ITEM_CANNOT_BE_GIVEN_BACK: "Objets perdus/cassés"
    },
    titleWithContactAndStatus: {
      REQUESTED: "Demandés à {{name}}",
      REQUEST_DECLINED: "Demandes refusées par {{name}}",
      ACCEPTED: "A recevoir de {{name}}",
      ITEM_BORROWED: "Empruntés à {{name}}",
      ITEM_GIFTED: "Donnés par {{name}}",
      ITEM_CANNOT_BE_GIVEN_BACK: "Perdus/cassés de {{name}}"
    }
  },
  myItemsPage: {
    title: "Mes objets",
    titleWithContact: "Mes objets passés à {{name}}",
    titleWithStatus: {
      REQUESTED: "Objets demandés",
      ACCEPTED: "Objets à recevoir",
      ITEM_BORROWED: "Objets prêtés",
      ITEM_GIFTED: "Objets donnés en cadeau",
      ITEM_CANNOT_BE_GIVEN_BACK: "Objets perdus/cassés"
    },
    titleWithContactAndStatus: {
      REQUESTED: "Demandés par {{name}}",
      ACCEPTED: "A passer à {{name}}",
      ITEM_BORROWED: "Passés à {{name}}",
      ITEM_GIFTED: "Donnés à {{name}}",
      ITEM_CANNOT_BE_GIVEN_BACK: "Perdus/cassés par {{name}}"
    }
  },
  addContactPage: {
    title: "Ajouter un contact",
    shareALink: "Partagez un lien pour ajouter un contact",
    explainLinkSharing: `<p><b>Envie de faire grandir votre Hamo et de partager la joie d'une parentalité moins isolée ?</b></p>
<p>
Invitez un autre parent à vous rejoindre en :</p>
<ul>
<li>lui envoyant ce lien, ou</li>
<li>lui faisant scanner ce QR code.</li>
</ul>
<p><b>
Attention, ces liens sont uniques, tout comme votre futur BFF !
Pour inviter d'autres personnes, cliquez sur "créer un nouveau lien" pour chaque nouveau contact.</b></p>`,
    getANewLink: "Créer un nouveau lien",
    share: "Partager",
    explainScanALink: "Demandez à votre contact d'ouvrir l'app Hamo sur cet écran, et scannez son QR code.",
    scanALink: "Scanner un lien",
    scan: "Scanner",
    shareLinkText: 'Connectez-vous avec {{name}} sur Hamo en utilisant ce lien.',
    shareLinkTitle: 'Connectez-vous sur Hamo'
  },
  browsePage: {
    title: "Chercher",
    searchPlaceholder: "Rechercher un jouet, livre,..."
  },
  browseUserItemsPage: {
    title: "Objets de {{name}}"
  },
  myItemPage: {
    titleShort: "Mes objets",
    title: "Tous mes objets"
  },
  myItemDetailPage: {
    title: "Mon objet",
    requestBy: "Demande de {{name}}"
  },
  inboxPage: {
    title: "Statut des échanges",
    myItems: "Mes objets",
    theirItems: "Leurs objets",
    myItemsMessage: {
      REQUESTED: "{{name}} me demande {{count}} objets",
      ACCEPTED: "Je dois passer {{count}} objets à {{name}}",
      ITEM_BORROWED: "J'ai passé {{count}} objets à {{name}}",
      ITEM_GIFTED: "J'ai donné {{count}} objets à {{name}}",
      ITEM_CANNOT_BE_GIVEN_BACK: "{{name}} a perdu/cassé {{count}} objets"
    },
    theirItemsMessage: {
      REQUESTED: "Je demande {{count}} objets à {{name}}",
      REQUEST_DECLINED: "{{name}} a refusé {{count}} de mes demandes",
      ACCEPTED: "Je dois m'arranger avec {{name}} pour qu'il me passe {{count}} objets",
      ITEM_BORROWED: "J'ai emprunté {{count}} objets à {{name}}",
      ITEM_GIFTED: "J'ai reçu en cadeau {{count}} objets de {{name}}",
      ITEM_CANNOT_BE_GIVEN_BACK: "J'ai perdu/cassé {{count}} objets de {{name}}"
    }
  },
  wishListPage: {
    title: "Favoris"
  },
  list: {
    noMoreItems: "Pas d'autres résultats..."
  },
  enums: {
    requestStatus: {
      CREATED: "Demande créée",
      REQUESTED: "Objet demandé",
      ACCEPTED: "Demande acceptée",
      REQUEST_DECLINED: "Demande refusée",
      CANCELLED: "Demande annulée",
      ITEM_BORROWED: "Objet emprunté",
      ITEM_GIFTED: "Objet donné",
      ITEM_GIVEN_BACK: "Objet rendu",
      ITEM_CANNOT_BE_GIVEN_BACK: "Objet cassé ou perdu"
    },
    plural: {
      requestStatus: {
        CREATED: "Demandes créées",
        REQUESTED: "Objets demandés",
        ACCEPTED: "Demandes acceptées",
        REQUEST_DECLINED: "Demandes refusées",
        CANCELLED: "Demandes annulées",
        ITEM_BORROWED: "Objets empruntés",
        ITEM_GIFTED: "Objets donnés",
        ITEM_GIVEN_BACK: "Objets rendus",
        ITEM_CANNOT_BE_GIVEN_BACK: "Objets cassés ou perdus"
      },
    },
    itemStatus: {
      HIDDEN: "Caché",
      AVAILABLE: "Disponible",
      REQUESTED: "Demandé",
      REQUEST_DECLINED: "Demande refusée",
      PENDING_DELIVERY: "Doit être transmis",
      BORROWED: "Emprunté",
      BORROWED_BY_ME: "Emprunté par moi",
      GIFTED_TO_ME: "Reçu en cadeau",
      UNAVAILABLE: "Pas disponible",
      GIFTED: "Donné",
      LOST_BROKEN: "Cassé ou perdu"
    },
    itemSharingDuration: {
      NEVER: "Caché",
      SHORT_TERM: "Partage court (quelques jours)",
      MEDIUM_TERM: "Partage moyen (quelques semaines)",
      LONG_TERM: "Partage long (plusieurs mois)",
      FOREVER: "Pour toujours (cadeau)"
    }
  },
  actions: {
    clearSelection: "Désélectionner tout",
    genericTitle: "Actions",
    noActionsTitle: "Pas d'actions pour cet objet",
    noActionsForSelectionTitle: "Pas d'actions pour les objets sélectionnés",
    requester: {
      requestItem: "Demander cet objet",
      requestMultipleItems: "Demander les {{count}} objet(s) disponible(s)",
      deleteDeclinedRequest: "Supprimer cette demande refusée",
      deleteMultipleDeclinedRequests: "Supprimer ces {{count}} demandes refusées",
      cancelRequest: "Annuler ma demande",
      cancelMultipleRequests: "Annuler ces {{count}} demande(s)",
      cannotGiveBack: "Cet objet ne peut être rendu"
    },
    owner: {
      acceptFrom: "Accepter la demande de {{name}}",
      declineFrom: "Refuser la demande de {{name}}",
      cancelFrom: "Annuler la demande de {{name}}",
      givenBackFrom: "A été rendu par {{name}}",
      giftedTo: "A été offert à {{name}}",
      cannotBeGivenBackFrom: "Ne peut être rendu par {{name}}",
      wasBorrowedBy: "A été emprunté par {{name}}",
      deleteItem: "Supprimer cet objet définitivement",
      deleteMultipleItems: "Supprimer ces {{count}} objets définitivement",
      updateDuration: "Changer la durée de partage",
      updateMultipleDuration: "Changer la durée de partage pour ces {{count}} objets"
    }
  },
  date: {
    itemRequested: "Objet demandé",
    modified: "mis à jour",
    onDay: "le",
    atTime: "à"
  },

  deleteItemModal: {
    delete: "Supprimer",
    dontDelete: "Ne pas supprimer",
    sentenceMultiple: "Etes-vous sûr de vouloir supprimer définitivement ces {{count}} objets ?",
    sentenceSingle: "Etes-vous sûr de vouloir supprimer définitivement cet objet ?",
    noCancel: "Attention: cette opération ne peut être annulée."
  }
  ,
  updateDurationModal: {
    dontUpdate: "Ne pas changer",
    update: "Changer"
  }
};
