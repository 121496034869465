/**
 * Hamo contract
 * Hamo contract
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ItemSharingDuration = 'NEVER' | 'SHORT_TERM' | 'MEDIUM_TERM' | 'LONG_TERM' | 'FOREVER';

export const ItemSharingDuration = {
    Never: 'NEVER' as ItemSharingDuration,
    ShortTerm: 'SHORT_TERM' as ItemSharingDuration,
    MediumTerm: 'MEDIUM_TERM' as ItemSharingDuration,
    LongTerm: 'LONG_TERM' as ItemSharingDuration,
    Forever: 'FOREVER' as ItemSharingDuration
};

