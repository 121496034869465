import {Injectable, signal, WritableSignal} from '@angular/core';
import i18next from "i18next";
import {LanguageEnum} from "../../../openapi-generated";
import {BehaviorSubject, Observable} from "rxjs";


@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  currentLang$$: WritableSignal<LanguageEnum> = signal(i18next.language as LanguageEnum);
  static langList: readonly LanguageEnum[] = [LanguageEnum.En, LanguageEnum.Fr, LanguageEnum.Nl];
  static langNames:
    { [key: string]: string } = {
    'fr': 'Français',
    'en': "English",
    'nl': "Nederlands"
  };

  constructor(){
    this.currentLang$.subscribe(l => document.documentElement.lang = l);
  }

  private currentLang$ = new BehaviorSubject<LanguageEnum>(this.currentLang$$());

  langList$$ = signal(LanguageService.langList);

  changeLang(lang: LanguageEnum
  ) {
    i18next.changeLanguage(lang).then(() => {
      this.currentLang$$.set(lang);
      this.currentLang$.next(lang);
    });
  }

  asObservable(): Observable<LanguageEnum>{
    return this.currentLang$.asObservable();
  }

}
