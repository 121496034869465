import {Directive, effect, TemplateRef, ViewContainerRef} from '@angular/core';
import {HttpStateService} from "../../../services/http-state.service";

@Directive({
  selector: '[appIfLoading]'
})
export class IfLoadingDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private vcRef: ViewContainerRef,
    public httpStateService: HttpStateService // Inject the service
  ) {
    effect(() => {
      if (httpStateService.inFlightCount() > 0) {
        this.vcRef.createEmbeddedView(this.templateRef);
      } else {
        this.vcRef.clear();
      }
    })

  }

}
